@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  // overflow-x: hidden;
}

//Components
@import "./components/team.scss";
@import "./components/popular-courses.scss";
@import "./components/course-desc.scss";
@import "./components/info.scss";
@import "./components/syllabus.scss";
@import "./components/faq.scss";
@import "./components/startHere.scss";
@import "./components/task-code-editor.scss";
@import "./components/info2.scss";
@import "./components/auth.scss";
@import "./components/caruselSignUp.scss";
@import "./components/calendar.scss";
@import "./components/instructors.scss";
@import "./components/new-footer.scss";
@import "./components/chat-box.scss";
@import "./components/auth-content.scss";

//Pages
// @import "./pages/certification.scss";
@import "./pages/home.scss";
@import "./pages/about.scss";
@import "./pages/course-detail.scss";
@import "./pages/contact.scss";
@import "./pages/course.scss";
@import "./pages/profile.scss";
@import "./pages/corporate.scss";
@import './pages/enroll.scss';
