.diagonal_lines {
    background-image: repeating-linear-gradient(
      -45deg,
      black 0,
      black 2px,
      transparent 0,
      transparent 50%
    );
    background-size: 6.5px 6.5px;
    background-color: #fff0e5;
  }
  