.scrollBarProblem {
    scroll-snap-type: x mandatory;
    scroll-padding: 1rem;
  }
  
  .scrollBar {
    scroll-snap-align: start;
  }
  
  // @media only screen and (min-width: 768px) {
  //   .scrollBarProblem {
  //     flex-direction: row;
  //   }
  // }
  
  // @media only screen and (min-width: 480px) {
  //   .scrollBarProblem {
  //     flex-direction: column !important;
  //     overflow-x: hidden;
  //   }
  // }
  
