.corporate {
  .logo {
    position: relative;
    z-index: 2;
    margin-bottom: 1rem;
    background-color: #0554f2;
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 50%;
    transition: all 0.2s ease;
    .icon {
      padding: 5px;
      color: white;
      transition: all 0.2s ease;
    }
  }
  .corporate-title {
    text-align: center;
    .sub-title {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #0554f2;
      font-size: 20px;
      font-weight: 500;
      gap: 2rem;
      margin-bottom: 10px;
      p {
        width: 50px;
        height: 2px;
        background: #0554f2;
        line-height: normal;
      }
    }
    h2 {
      font-family: Georgia, "Times New Roman", Times, serif;
      font-size: 36px;
      font-weight: 500;
      color: #10162f;
      span {
        display: block;
        margin-top: -10px;
      }
    }
  }
  .corporate-first {
    background: #f4f9ff;
    padding: 60px 100px;
    .executions {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 2rem;
      margin-top: 2rem;
      overflow: hidden;
      padding: 1rem;
      .execution-item {
        cursor: pointer;
        padding: 3rem 2rem 2rem 1rem;
        background: white;
        box-shadow: 0 2px 4px rgba($color: black, $alpha: 0.2);
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        &:hover {
          .logo {
            background-color: white;
            svg {
              color: #0554f2 !important;
            }
          }
          .execution-item-shadow-effect {
            div {
              background-color: #0554f2;
              animation-name: fadeUp;
              transform: translateY(0);
            }
          }
        }

        .execution-item-shadow-effect {
          position: absolute;
          background: #fbfbfb;
          left: -10%;
          top: -53%;
          width: 324px;
          height: 256px;
          background: #fbfbfb;
          border-radius: 0px 0px 255px 0px;
          transition: all 0.2s ease;
          div {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 0px 0px 255px 0px;
            animation-duration: 0.5s;
            animation-name: fadeDown;
            transform: translateY(-100%);
            background-color: #0554f2;
          }
        }
        h2 {
          color: #10162f;
          font-size: 22px;
          font-weight: 600;
        }
        p {
          font-size: 1rem;
          margin: 10px 0;
        }
        a {
          font-size: 15px;
          text-transform: uppercase;
          font-weight: 500;
        }
      }
    }
  }
  .corporate-second {
    background: #02031f;
    padding: 60px 100px;
    .services {
      .corporate-title {
        margin-bottom: 30px;
        .sub-title {
          p {
            background-color: white;
          }
          color: white;
        }
        h2 {
          color: white;
        }
      }
      .all-services {
        display: grid;
        width: 80%;
        margin: 0 auto;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
        .service-item {
          padding: 3rem 2rem 2rem 2rem;
          border-radius: 10px;
          position: relative;
          overflow: hidden;
          cursor: pointer;
          background: #08092a;
          &:hover {
            .service-shadow-fill {
              animation-name: full;
              height: 100%;
              transform: translateY(0);
            }
            h2 {
              color: #10162f;
            }
            .logo {
              .logo-ring {
                rotate: 80deg;
              }
            }
          }
          .service-shadow-fill {
            position: absolute;
            width: 100%;
            height: 100%;
            transform: translateY(calc((0px - 100% - 1px)));
            background: white;
            top: 0;
            left: 0;
            animation-duration: 0.6s;
            animation-name: empty;
          }
          .logo {
            .logo-ring {
              position: absolute;
              width: 75px;
              height: 75px;
              transition: all 0.3s ease;
              svg {
                fill: #0554f2;
              }
            }
          }
          h2 {
            position: relative;
            z-index: 1;
            color: white;
            font-size: 22px;
            margin-bottom: 10px;
          }
          p {
            position: relative;
            z-index: 1;
            color: #aeaeae;
            font-size: 1rem;
          }
        }
      }
    }
  }
  .letter {
    background-color: white;
    padding: 30px 40px;
    .letter-bg {
      background: #0554f2;
      border-radius: 10px;
      width: 80%;
      padding: 4rem 2rem;
      margin: 0 auto;
      color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      h2 {
        font-size: 34px;
        font-weight: 600;
        width: 50%;
      }
      .letter-contact {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        p {
          font-size: 15px;
          text-transform: uppercase;
        }
        a {
          font-size: 30px;
          font-weight: 500;
        }
        .call-logo {
          cursor: pointer;
          width: 60px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          border: 1px solid white;
          padding: 5px;
        }
      }
    }
  }
}

@keyframes fadeUp {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes fadeDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100px);
  }
}
@media (max-width: 1200px) {
  .corporate {
    .corporate-first,
    .corporate-second {
      padding: 60px 30px;
    }
    .letter {
      padding: 30px;
      .letter-bg {
        width: 90%;
        h2 {
          font-size: 32px;
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .corporate {
    .corporate-first,
    .corporate-second {
      padding: 30px 15px;
    }
    .letter {
      padding: 20px;
      .letter-bg {
        text-align: center;
        width: 95%;
        flex-direction: column;
        h2 {
          width: 100%;
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .corporate {
    .corporate-first {
      .executions {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    .corporate-second {
      .services {
        .all-services {
          width: 90%;
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
    .letter {
      .letter-bg {
        .letter-contact {
          a {
            font-size: 26px;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .corporate {
    .corporate-title {
      .sub-title {
        font-size: 18px;
      }
      h2 {
        font-size: 26px;
      }
    }
    .corporate-first {
      padding: 30px 5px;
      .executions {
        grid-template-columns: 1fr;
      }
    }
    .corporate-second {
      width: 100%;
      .services {
        .all-services {
          width: 100%;
          grid-template-columns: 1fr;
        }
      }
    }
    .letter {
      .letter-bg {
        h2 {
          font-size: 28px;
        }
        .letter-contact {
          a {
            font-size: 24px;
          }
        }
      }
    }
  }
}
@media (max-width: 570px) {
  .corporate {
    .letter {
      padding: 10px;
      .letter-bg {
        padding: 4rem 1rem;
        h2 {
          font-size: 28px;
        }
        .letter-contact {
          .call-logo {
            width: 50px;
            height: 50px;
            padding: 4px;
          }
          a {
            font-size: 22px;
          }
        }
      }
    }
  }
}
@keyframes full {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes empty {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}
