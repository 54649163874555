.profile {
  background: #f5fcff;
  padding: 4rem 6rem;
  display: flex;
  height: 100vh;
  border-bottom: 1px solid black;
  justify-content: space-between;
  .profile-info {
    width: 25%;
    .profile-img {
      height: 120px;
      width: 120px;
      img {
        aspect-ratio: 1 / 1;
        border-radius: 50%;
      }
    }
    .name-and-desc {
      .name {
        font-weight: 600;
        font-size: 26px;
        padding: 12px 0;
      }
      .linkedin {
        font-size: 1rem;
        color: blue;
        text-decoration: underline;
      }
      .description {
        font-size: 1rem;
      }
    }
  }
  .category-course {
    h2 {
      font-size: 22px;
      color: #10162f;
      font-weight: 600;
    }
    .category {
      p {
        font-size: 1rem;
        margin: 1rem 0;
      }
    }
    .course {
      div {
        cursor: pointer;
        margin: 1rem 0;
        background: #ffffff;
        padding: 0 1rem;
        color: #10162f;
        width: 300px;
        a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .type {
            font-size: 0.875rem;
          }
          .name {
            font-size: 1rem;
            line-height: 1.5;
            font-weight: 700;
          }
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .profile {
    padding: 4rem;
  }
}

@media (max-width: 1000px) {
  .profile {
    padding: 4rem 6rem;
  }
  .ingress-logo {
    display: none;
  }
}

@media (max-width: 768px) {
  .profile {
    position: relative;
    padding: 4rem 2rem;
    flex-direction: column;
    .profile-info {
      width: 60%;
      margin-bottom: 2rem;
    }
  }
  .ingress-logo {
    display: block;
    position: absolute;
    right: 40px;
    top: 200px;
  }
}

@media (max-width: 576px) {
  .profile {
    .profile-info {
      width: 100%;
    }
    .category-course {
      .course {
        div {
          width: 100%;
        }
      }
    }
  }
  .ingress-logo {
    display: none;
  }
}
