.info {
    background: #10162f;
    color: white;
    height: auto;
    padding: 1rem 0;
    .inner-info {
      display: flex;
      align-items: center;
      gap: 1rem;
      justify-content: space-between;
      margin: 0 auto;
      width: 70%;
      .info-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        .item-title {
          font-weight: 700;
          font-size: 1.875rem;
          line-height: 2.25rem;
        }
        .item-content {
          margin-top: 0.5rem;
          text-transform: uppercase;
          font-size: 0.75rem;
          line-height: 1rem;
        }
      }
    }
  }
  
  @media (max-width: 1024px) {
    .info {
      .inner-info {
        width: 90%;
      }
    }
  }
  @media (max-width: 480px) {
    .info {
      .inner-info {
        flex-direction: column;
      }
    }
  }
  