.home-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 2rem; 
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    gap: 4rem; 
  
    @media (min-width: 1024px) {
      flex-direction: row;
    }
  }
  