.chat-box {
  background: #f5f5f5;
  position: fixed;
  animation-duration: 0.3s;
  animation-name: unshift;
  width: 400px;
  transform: translateY(0);
  height: 400px;
  right: 1rem;
  bottom: 0;
  z-index: 99999;
  border-radius: 5px 30px 30px 5px;
  .box-head {
    border-radius: 5px 30px 0 0;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    font-size: 1rem;
    background: #fff;
    box-shadow: 2px 2px 2px 0 rgba($color: #000000, $alpha: 0.1);
    .box-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
    }
    .box-name {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
  .box-content {
    padding: 1rem;
    .question {
      padding: 1rem;
      font-size: 0.9rem;
      background: rgba(black, 0.05);
      margin-bottom: 0.5rem;
    }
    .user-questions {
      font-size: 0.85rem;
      .user-question {
        width: fit-content;
        border-radius: 10px;
        margin-bottom: 0.4rem;
        padding: 0.5rem;
        cursor: pointer;
        position: relative;
        z-index: 2;
        transition: all 0.3s ease;
        &:nth-of-type(even) {
          background: #fff;
          div {
            border-top-color: #fff;
          }
        }
        &:nth-of-type(odd) {
          background-color: #fff;
          div {
            border-top-color: #fff;
          }
        }
        div {
          border: 9px solid transparent;
          border-radius: 4px;
          bottom: auto;
          content: "";
          height: 0;
          left: -9px;
          position: absolute;
          top: 0;
          width: 0;
          z-index: 1;
        }
        &:hover {
          box-shadow: 0 2px 4px rgba($color: black, $alpha: 0.1);
        }
      }
    }
  }
  .message-box {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 10px;
    .message {
      padding: 1rem;
      width: fit-content;
      background: #0554f2;
      color: white;
      position: relative;
      border-radius: 10px;
      font-size: 0.8rem;
      div {
        border: 9px solid transparent;
        border-radius: 4px;
        bottom: auto;
        content: "";
        height: 0;
        right: -9px;
        position: absolute;
        top: 0;
        width: 0;
        z-index: 1;
        border-top-color: #0554f2;
      }
    }
  }
}
.passive {
  animation-name: shift;
  transform: translateY(350px);
}

@keyframes unshift {
  0% {
    transform: translateY(350px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes shift {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(350px);
  }
}
