.inputStyle {
  border: 1px solid rgba(16, 22, 47, 0.63);
  transition: background-color 350ms ease-in-out 0s,
    box-shadow 350ms ease-in-out 0s;
  outline: none;
}

.inputStyle:hover {
  border-color: #3a10e5;
}

.inputStyle:focus {
  box-shadow: inset 0 0 0 1px #3a10e5;
  border-color: #3a10e5;
}

.inputStyle.inValid {
  border: 1px solid rgb(183 28 28);
}

.inputStyle.inValid:focus {
  box-shadow: inset 0 0 0 1px rgb(183 28 28);
  border-color: rgb(183 28 28);
}
.inputStyle::placeholder {
  color: rgba(16, 22, 47, 0.63);
}

.submitButton {
  cursor: pointer;
  width: 100%;
  transition: background-color 150ms ease-in, box-shadow 150ms ease-in;
  border: 2px solid #10162f;
  font-weight: 700;
  height: 40px;
  min-width: 40px;
  border-radius: 4px;
  padding: 0.25rem 1rem;
  font-size: 1rem;
  color: white;
}

.list_element_style {
  position: relative;
  margin-left: 2rem;
  margin-bottom: 0.5rem;
  line-height: 1.6;
  color: black;
  font-size: 1rem;
}

.list_element_style::before {
  border-radius: 100%;
  content: "";
  display: inline-block;
  height: 0.5rem;
  left: -1.25rem;
  line-height: 1.6;
  position: absolute;
  text-align: center;
  top: 0.6rem;
  width: 0.5rem;
  background-color: black;
}

.modalOverlay {
  position: absolute;
  top: 25%;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .modalContent {
    width: 100%;
    height: 250px;
    overflow-y: auto;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    .course {
      cursor: pointer;
    }
  }
}
