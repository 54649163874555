.info-2{
    background-color: #3a10e5;
    padding: 6rem 0;
    .inner-info-2{
        width: 85%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: white;
        .info-2-left{
            width: 430px;
            h2{
                font-size: 2.75rem;
                font-weight: 500;
            }
        }
        .info-2-right{
            display: flex;
            align-items: center;
            column-gap: 3rem;
            flex-wrap: wrap;
            width:100%;
            justify-content: flex-end;
            .info-item-2 {
                display: flex;
                flex-direction: column;
                align-items: center;
                .item-title-2 {
                  font-weight: 700;
                  font-size: 2.75rem;
                  line-height: 2.25rem;
                }
                .item-content-2 {
                  margin-top: 1.5rem;
                  text-transform: uppercase;
                  font-size: 20px;
                  line-height: 1rem;
                }
              }
        }
    }
}
  @media (max-width:1200px){
    .info-2{
      .inner-info-2{
        width: 90%;
        .info-2-right{
          column-gap: 1.5rem;
        }
      }
    }
  }
  @media (max-width:1024px){
    .info-2{
      .inner-info-2{
        .info-2-right{
          row-gap: 1rem;
          .info-item-2{
            width: 40%;
          }
        }
      }
    }
  }
  @media (max-width: 960px) {
    .info-2 {
      padding: 4rem 0 ;
      .inner-info-2 {
        align-items: flex-start;
        width: 95%;
        .info-2-right{
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
          .info-item-2{
            width: 100%;
          }
        }
      }
    }
  }
  @media (max-width: 480px) {
    .info-2 {
      .inner-info-2 {
        align-items: center;
        flex-direction: column;
        row-gap: 1rem;
        .info-2-left{
          width: 100%;
          h2{
            font-size: 34px;
            text-align: center;
            margin-bottom: 1rem;
          }
        }
        .info-2-right{
          flex-wrap: wrap;
          flex-direction: row;
          column-gap: 0;
          row-gap:3rem;
        }
      }
    }
  }
  