.contact-map {
  position: relative;
  .bg-wh {
    position: absolute;
    top: 100px;
    right: 100px;
    background-color: #ffffff;
    width: 507px;
    height: 616px;
    box-shadow: 40px 20px 100px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    .p-32 {
      padding: 32px;
      form {
        h3 {
          font-weight: bold;
          font-size: 32px;
          line-height: 48px;
          color: #10162f;
          margin-bottom: 31px;
          font-family: "SF-Pro-Display-Bold";
        }
        .formgroup {
          margin-bottom: 16px;
          label {
            font-size: 14px;
            line-height: 24px;
            color: #2e3131;

            &.required::after {
              content: "*";
              color: red;
              margin-left: 3px;
            }
          }
          .error {
            color: red;
            font-size: 0.7rem;
            margin-top: 0.25rem;
          }
          input,
          textarea {
            width: 100%;
            border: 1px solid #e4e8ee;
            box-sizing: border-box;
            border-radius: 4px;
            margin-top: 8px;
            font-size: 14px;

            &:focus {
              outline: none;
            }
          }
          input {
            height: 40px;
            padding: 6px 12px;
          }
          textarea {
            padding: 12px 16px;
            min-height: 120px;
          }
          .textarea-counter,
          span {
            font-size: 12px;
            line-height: 16px;
            text-align: right;
            color: #acb3b8;
            margin-top: 8px;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  // .contact-us {
  //   background-color: #10162f;
  //   padding: 72px 88px;
  //   display: flex;
  //   justify-content: space-between;
  //   color: #fff;
  //   .ingress {
  //     display: flex;
  //     flex-wrap: wrap;
  //     column-gap: 5rem;
  //     row-gap: 2rem;
  //     .ingress-adress {
  //       display: flex;
  //       flex-direction: column;
  //       row-gap: 20px;
  //       h3 {
  //         margin: 0;
  //         font-style: normal;
  //         font-weight: 600;
  //         font-size: 20px;
  //         color: #fff;
  //       }
  //       span {
  //         font-style: normal;
  //         font-weight: 500;
  //         font-size: 16px;
  //         line-height: 2.4;
  //       }
  //     }
  //     .ingress-contact {
  //       display: flex;
  //       flex-direction: column;
  //       row-gap: 20px;
  //       h3 {
  //         font-weight: 600;
  //         font-size: 20px;
  //         line-height: 32px;
  //         color: #fff;
  //         text-transform: uppercase;
  //         margin: 0;
  //       }
  //       .contact-number {
  //         font-style: normal;
  //         font-weight: 500;
  //         font-size: 16px;
  //         line-height: 2.4;
  //         p {
  //           margin: 0;
  //         }
  //       }
  //     }
  //   }
  //   .contact-social {
  //     margin-right: 1rem;
  //     display: flex;
  //     column-gap: 36px;
  //     align-items: center;
  //     ul {
  //       margin: 0;
  //       display: flex;
  //       column-gap: 16px;
  //       color: #fff;
  //       li {
  //         a {
  //           color: #fff;
  //           font-size: 30px;
  //           &:hover {
  //             color: #1890ff;
  //           }
  //         }
  //       }
  //     }
  //     p {
  //       margin: 0;
  //       font-size: 1rem;
  //     }
  //   }
  // }
  @media (max-width: 1200px) {
    .bg-wh {
      top: 400px;
      right: 10px;
    }
    // .contact-us {
    //   flex-direction: column;
    //   row-gap: 2rem;
    //   padding-left: 2rem;
    //   padding-right: 0;
    //   .ingress {
    //     width: 30%;
    //   }
    // }
  }
  @media (max-width: 990px) {
    .p-88 {
      .d-flex {
        flex-wrap: wrap;
        justify-content: center;

        .contact-ingress {
          margin: 40px 0;
        }
      }
      .contact-icons {
        margin-left: 0px !important;
      }
    }
  }
  @media (max-width: 768px) {
    .bg-wh {
      position: static;
      width: 100%;
    }
    // .contact-us {
    //   padding-top: 2rem;
    //   padding-bottom: 2rem;
    //   align-items: center;
    //   justify-content: center;
    //   .ingress {
    //     width: 100%;
    //     justify-content: center;
    //   }
    // }
  }

}
