.calendar {
  position: relative;
  .react-calendar {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background: white;
    border: none;
    height: 350px;
    font-size: 1rem;
    border-radius: 5px;
    abbr {
      text-decoration: none;
    }
  }
  .modal-overlay {
    font-size: 1rem;
    position: fixed;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    height: 100%;
    width: 100%;
    .modal-content {
      padding: 20px;
      background-color: white;
      border-radius: 5px;
      text-align: center;
      form {
        padding: 10px 20px;
        height: 100%;
        display: flex;
        align-items: center;
        gap: 3rem;
        flex-direction: column;
        justify-content: space-between;
        .input-group {
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 1rem;
          input {
            width: 100%;
            border: 1px solid #ccc;
            padding: 5px 10px;
            outline: none;
          }
        }
        .button-group {
          display: flex;
          align-items: center;
          gap: 1rem;
          width: 100%;
          justify-content: flex-end;
          button {
            height: 30px;
            width: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
          }
          .create {
            background: blue;
            color: white;
          }
        }
      }
    }
  }
}

/* Add any additional styling as needed */
