.swiperContainer{
  min-height: 500px;
  height: 500px;
}
.slider-img {
  position: relative;
  width: 100%;
  margin: 0 auto 50px auto;
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  img{
    object-fit: cover;
  }
  .content {
    text-shadow: 0.5px 0.5px 0.5px black;
    border-radius: 20px;
    padding: 16px;
    background-color: rgba($color: black, $alpha: 0.05);
  }
}
.swiper-button-next,
.swiper-button-prev {
  opacity: 0 !important;
}
.career_title_content {
  font-weight: 700;
  font-size: 2.5rem;
  padding: 0.25rem;
  background-color: #fff0e5;
  text-transform: uppercase;
  line-height: 55px;
  display: inline;
  color: #10162f;
}

.image-name {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  text-transform: uppercase;
}
.absolute-boxes {
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;
  .grid-box {
    display: grid;
    grid-template-columns: 1fr;
    width: 90%;
    gap: 1.5rem;
    margin: 0 auto;
    .grid-item {
      &:hover {
        .rotated {
          animation-name: rotate;
          :first-child {
            opacity: 0;
          }
          :last-child {
            opacity: 1;
          }
        }
      }
      .rotated {
        --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1),
          0 2px 4px -2px rgb(0 0 0 / 0.1);
        --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
          0 2px 4px -2px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
          var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        position: relative;
        width: 100%;
        height: 100%;
        animation-duration: 0.5s;
        animation-name: reverse;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        div {
          padding: 3rem 1.5rem;
          border-radius: 5px;
          &:first-child {
            background: white;
            transition: 0.5s ease;
            color: black;
            font-weight: 600;
            font-size: 20px;
            .grid-box-title{
              text-align: center;
            }
            .rotated-icon {
              color: #0c5adb;
              font-size: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 20px;
            }
          }
          &:last-child {
            padding: 1rem 1.5rem;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            transition: 0.5s ease;
            height: 100%;
            width: 100%;
            background: #0c5adb;
            color: white;
            text-align: center;
            .grid-box-title {
              font-size: 18px;
              margin-bottom: 10px;
              opacity: 1 !important;
              color: white;
              font-weight: 600;
            }
            p {
              font-size: 14px;
              letter-spacing: 1px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 480px) {
  .career_info {
    left: 32px;
  }

  // .swiperContainer {
  //   height: calc(85vw + 48px);
  // }

  .swiper-slide img {
    margin-bottom: 80px;
  }
}

@media only screen and (min-width: 768px) {
  // .swiperContainer {
  //   height: calc(80vw);
  // }
  .swiperContainer{
    height: 700px;
    min-height: 700px;
  }
  .slider-img {
    height: 700px;
  }
  .career_title {
    max-width: 500px;
  }

  .career_title_content {
    font-size: 72px;
    line-height: 1.2em;
  }

  .career_info {
    left: 64px;
  }
  .swiper-slide img {
    margin-bottom: 80px;
  }
  .absolute-boxes {
    .grid-box {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media only screen and (min-width: 1024px) {
  .career_title {
    max-width: 300px;
  }
  .career_title_content {
    font-size: 2.75rem;
  }

  .swiper-slide img {
    margin-bottom: 80px;
  }
  .swiper-button-next,
  .swiper-button-prev {
    opacity: 1 !important;
    color: white !important;
    height: 200px;
    width: 200px;
    padding: 2rem;
    background: rgba($color: #000000, $alpha: 0.3);
  }
  .absolute-boxes {
    bottom: -20px;
    .grid-box {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

@media only screen and (min-width: 1200px) {
  .career_title {
    max-width: 450px;
  }

  .career_title_content {
    font-size: 4rem;
  }
  .career_info {
    left: 96px;
  }

  .swiper-slide img {
    margin-bottom: 80px;
  }

  .absolute-boxes {
    .grid-box {
      grid-template-columns: repeat(4, 1fr);
      width: 80%;
    }
  }
}

@media only screen and (min-width: 1440px) {
  .career_title {
    max-width: 500px;
  }

  .career_title_content {
    font-size: 72px;
  }

  .swiper-slide img {
    margin-bottom: 70px;
  }
}

.swiper {
  width: 100%;

  transition: all 0.5s cubic-bezier(0.5, 0.2, 0.3, 1.1) 0s;
  transform: translateX(0px);
}

.swiper-slide {
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: flex-end;
  font-size: 18px;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  width: auto !important;
  left: calc(50% - 46px) !important;
  height: 36px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  padding: 0 8px;
  border-radius: 18px;
  gap: 8px;
}

.swiper-pagination .swiper-pagination-bullet {
  background-color: transparent;
  width: 12px;
  height: 12px;
  border: 1px solid black;
  opacity: 100% !important;
}

.swiper-pagination-bullet-active {
  background-color: black !important;
}

@keyframes reverse {
  0% {
    rotate: y 90deg;
  }
  100% {
    rotate: y 0deg;
  }
}

@keyframes rotate {
  0% {
    rotate: y 0deg;
  }
  50% {
    rotate: y 90deg;
  }
  100% {
    rotate: y 0deg;
  }
}
