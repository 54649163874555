.instructors {
  padding: 10px;
  margin: 100px 0 50px 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 2rem;
  .instructor {
    width: 100%;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        aspect-ratio: 1 / 1;
        object-fit: cover;
      }
      h2 {
        margin: 10px 0;
        font-size: 20px;
        font-weight: 500;
      }
      span {
        font-size: 1rem;
        text-align: center;
      }
    }
  }
}

@media (max-width: 1200px) {
  .instructors {
    justify-content: space-between;
  }
}

@media (max-width: 768px) {
  .instructors {
    flex-wrap: wrap;
    justify-content: center;
    .instructor {
      width: 45%;
    }
  }
}
