.course-detail {
  background: white;
  position: relative;
  z-index: 10;
  .inner-course-detail {
    padding: 2.8rem;
    background-image: radial-gradient(#000000 0.6px, transparent 0.6px),
      radial-gradient(#000000 0.6px, transparent 0.6px);
    background-size: 6px 6px;
    background-position: 0 0, 3px 3px;
    .course-container {
      width: 80%;
      margin: 0 auto;
      font-family: "Apercu", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
      .course-info {
        border-radius: 9px;
        padding: 2rem;
        background-color: white;
        .inner-course-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          column-gap: 1rem;
          row-gap: 2rem;
          .info-left {
            max-width: 100%;
            width: 70%;
            min-width: 70%;
            .course-course {
              margin-bottom: 10px;
              font-size: 1.125rem;
            }
            .course-name {
              font-weight: 700;
              font-size: 2.5rem;
              margin-bottom: 1rem;
            }
            .course-content {
              font-size: 1.125rem;
              font-weight: 400;
            }
          }
          .course-skills {
            width: 100%;
            .about-header-item {
              display: flex;
              align-items: center;
              gap: 1rem;
            }
          }
        }
      }
      .course-description {
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 2rem;
        border: 1px solid #000000;
        background: white;
        font-size: 1rem;
        row-gap: 1rem;
        .course-desc-item {
          text-align: center;
          display: flex;
          align-items: center;
          gap: 1rem;
          .icon {
            font-size: 2rem;
            + div {
              div {
                &:first-child {
                  letter-spacing: 2px;
                }
              }
            }
          }
          .det {
            font-weight: 600;
            font-size: 1.375rem;
            .animated-border-container {
              position: relative;
              display: inline-block;
              // padding: 10px 20px;
              overflow: hidden;
            }
            
            .animated-border-container::before,
            .animated-border-container::after {
              content: '';
              position: absolute;
              width: 100%;
              height: 2px;
              background: linear-gradient(90deg, transparent, #3a10e5, transparent);
              animation: borderFlash 2s linear infinite;
            }
            
            .animated-border-container::before {
              top: 0;
              left: -100%;
            }
            
            .animated-border-container::after {
              bottom: 0;
              right: -100%;
              animation-delay: 1s;
            }
            
            .sparkle-link {
              position: relative;
              z-index: 1;
              transition: all 0.3s ease;
            }
            
            @keyframes borderFlash {
              0% {
                transform: translateX(-100%);
              }
              100% {
                transform: translateX(100%);
              }
            }
            div {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 0.2rem;
            }
            span {
              display: block;
              font-size: 0.8rem;
              a {
                color: blue;
              }
            }
          }
        }
      }
    }
  }
  .about-course {
    width: 70%;
    margin: 0 auto;
    padding: 3rem 1rem;
    .about-flex {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 50px;
      .w70 {
        width: 70%;
      }
      .w30 {
        .about-header-item {
          display: flex;
          align-items: center;
          gap: 1rem;
          .logo {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
  .hands {
    background-color: #10162f;
    color: white;
    .hands-container {
      width: 70%;
      margin: 0 auto;
      padding: 4rem 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;
      .content {
        width: 45%;
        div {
          font-size: 1rem;
          letter-spacing: 2px;
          font-weight: 700;
        }
        h2 {
          font-size: 34px;
          font-weight: 700;
          margin-bottom: 1rem;
        }
        p {
          font-size: 1.125rem;
        }
      }
      .gif {
        position: relative;
        z-index: 0;
        height: 275px;
        width: 60%;
        gap: 2rem;
        margin: 0 auto;
      }
    }
  }
  .projects {
    width: 80%;
    margin: 3rem auto 0 auto;
    padding-bottom: 3rem;
    .head {
      text-align: center;
      padding: 0 0.75rem;
      font-size: 34px;
      color: #10162f;
      margin-bottom: 3rem;
      font-weight: 700;
    }
    .all-flex {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      justify-content: center;
      gap: 2rem;
      :not(.p-rel).box {
        cursor: pointer;
        transition: 0.5s ease;
        box-shadow: 0 0 0 black;
        padding: 0;
        a {
          display: block;
          padding: 1rem;
        }
        &:hover {
          box-shadow: -8px 8px 0 black;
          transform: translate(4px, -4px);
        }
      }
      .box {
        font-family: "Apercu", -apple-system, BlinkMacSystemFont, "Segoe UI",
          "Roboto", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
          "Helvetica Neue", sans-serif;
        padding: 1rem;
        border: 1px solid black;
        background-color: #ffffff;
        color: #10162f;
        width: 285px;
        p {
          font-size: 14px;
          letter-spacing: 2px;
          margin-bottom: 0.75rem;
        }
        h3 {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 0.25rem;
        }
        .break {
          font-size: 1rem;
          letter-spacing: 1px;
          text-overflow: ellipsis;
          margin: 0;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
        }
      }
    }
    .reviews {
      margin-top: 3rem;
      .gap {
        row-gap: 4rem;
      }
      h2 {
        text-align: center;
      }
      .p-rel {
        position: relative;
        padding: 3rem 2rem 1.5rem 1rem;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 2rem;
        height: auto;
        .content {
          font-weight: bold;
          font-size: 1rem;
          letter-spacing: 0.5px;
        }
        .author {
          font-family: "Suisse", "Apercu", -apple-system, BlinkMacSystemFont,
            "Segoe UI", "Roboto", "Ubuntu", "Cantarell", "Fira Sans",
            "Droid Sans", "Helvetica Neue", sans-serif;
          font-size: 14px;
          letter-spacing: 2px;
        }
        svg {
          position: absolute;
          top: -18px;
        }
        &:first-child {
          background-color: #fffae5;
        }
        &:nth-child(2) {
          background-color: #fff5ff;
        }
        &:last-child {
          background-color: #f5fcff;
        }
      }
    }
  }
  .faq {
    width: 90%;
    margin: 0 auto;
    padding: 3rem 6rem;
  }
  .start-course {
    background-color: #ffd300;
    padding: 4rem 6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .content {
      width: 50%;
    }
    h2 {
      font-size: 2.75rem;
      color: #10162f;
      font-weight: 700;
      margin-bottom: 1.5rem;
    }
  }
  .extra-info {
    padding: 3rem;
    width: 80%;
    margin: 0 auto;
    h2 {
      font-size: 2.125rem;
      line-height: 1.3;
      font-weight: 700;
      text-align: center;
      color: #10162f;
      margin-bottom: 3rem;
    }
    h3 {
      font-weight: 700;
      line-height: 1.3;
      font-size: 1.625rem;
      margin: 3rem 0 2rem 0;
    }
    .related {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .unlock {
    background: #10162f;
    padding: 3rem;
    .inner-unlock {
      display: flex;
      justify-content: center;
      gap: 6rem;
      .unlock-image {
        position: relative;
        z-index: 0;
        width: 25%;
        height: 370px;
        img {
          object-fit: cover;
        }
      }
      .unlock-content {
        color: #ffffff;
        width: 40%;
        h2 {
          font-size: 2.125rem;
          font-weight: 700;
          line-height: 1.3;
        }
        li {
          display: flex;
          align-items: center;
          gap: 0.75rem;
          margin-top: 1.5rem;
          h3 {
            font-size: 1.125rem;
            font-weight: 700;
          }
          p {
            font-size: 1rem;
          }
        }
      }
    }
  }
  .about-header {
    font-weight: 600;
    line-height: 1.3;
    font-size: 1.375rem;
    margin-bottom: 0.5rem;
    + div {
      font-size: 1rem;
    }
  }
}
.skill-path {
  .inner-course-detail {
    background-color: #d3f2ff;
  }
}
.career-path {
  .inner-course-detail {
    background-color: #262a3f;
    .course-container {
      .course-info {
        background-color: #10162f;
        color: white;
      }
    }
  }
}
.shadow {
  position: absolute;
  top: 10px;
  left: -10px;
  background-size: 6px 6px;
  background-position: 0 0, 3px 3px;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.white {
  background-image: radial-gradient(#ffffff 0.6px, transparent 0.6px),
    radial-gradient(#ffffff 0.6px, transparent 0.6px);
}
.black {
  background-image: radial-gradient(#000000 0.6px, transparent 0.6px),
    radial-gradient(#000000 0.6px, transparent 0.6px);
}
.start {
  width: 230px;
  height: 45px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transition: opacity 0.5s ease;
  margin-top: 20px;
  font-size: 1rem;

  &:hover {
    opacity: 0.8;
  }
}
@media (max-width: 1440px) {
  .course-detail {
    .extra-info {
      width: 85%;
    }
  }
}
@media (max-width: 1200px) {
  .course-detail {
    .inner-course-detail {
      .course-container {
        width: 90%;
        .course-info {
          .inner-course-info {
            .info-left {
              min-width: 50%;
            }
          }
        }
      }
    }
    .about-course {
      width: 85%;
      padding: 3rem;
    }
    .hands {
      .hands-container {
        width: 85%;
        padding: 3rem;
        .content {
          width: 65%;
        }
        .gif {
          height: 230px;
        }
      }
    }
    .faq {
      padding: 3rem 2rem;
    }
    .unlock {
      .inner-unlock {
        .unlock-image {
          width: 40%;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .course-detail {
    .inner-course-detail {
      .course-container {
        .course-info {
          .inner-course-info {
            flex-direction: column;
            align-items: start;
            .info-left {
              width: 100%;
            }
          }
        }
        .course-description {
          justify-content: center;
          .course-desc-item {
            flex: 1 1 auto;
            width: 50%;
          }
        }
      }
    }
    .about-course {
      width: 90%;
      .about-flex {
        flex-direction: column;
        row-gap: 2rem;
        .w70 {
          width: 100%;
        }
      }
    }
    .hands {
      .hands-container {
        width: 90%;
        .gif {
          height: 200px;
        }
      }
    }
    .projects {
      width: 90%;
    }
    .extra-info {
      width: 100%;
      .related {
        flex-direction: column;
        row-gap: 2rem;
      }
    }
  }
}
@media (max-width: 968px) {
  .course-detail {
    .start-course {
      .bg-illustration {
        img {
          object-fit: contain;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .course-detail {
    .extra-info {
      padding: 1rem;
      h2 {
        font-size: 25px;
      }
      h3 {
        font-size: 18px;
        text-align: center;
      }
    }
    .inner-course-detail {
      padding: 2.1rem 0;
      .course-container {
        width: 90%;
        .course-info {
          .flex {
            display: none;
          }
          .course-content {
            width: 100%;
          }
        }
        .course-description {
          .course-desc-item {
            width: 100%;
            justify-content: center;
          }
        }
      }
    }
    .about-course {
      padding: 3rem 0;
    }
    .hands {
      .hands-container {
        padding: 3rem 0;
        flex-direction: column;
        align-items: flex-start;
        .content {
          width: 100%;
          margin-bottom: 30px;
        }
        .gif {
          width: 100%;
          height: 380px;
          img {
            object-fit: fill;
            aspect-ratio: 1 / 1;
          }
        }
      }
    }
    .projects {
      width: 100%;
      .all-flex {
        padding: 0.75rem;
        .box {
          width: 100%;
        }
      }
    }
    .faq {
      width: 100%;
    }
    .start-course {
      flex-direction: column-reverse;
      padding: 2rem 1rem;
      .content {
        width: 100%;
        text-align: center;
      }
    }
    .unlock {
      .inner-unlock {
        flex-direction: column;
        .unlock-image {
          width: 100%;
          height: 450px;
        }
        .unlock-content {
          width: 100%;
        }
      }
    }
  }
}
@media (max-width: 480px) {
  .course-detail {
    .inner-course-detail {
      .course-container {
        .course-info {
          .course-name {
            font-size: 2.125rem;
          }
        }
      }
    }
    .unlock {
      padding-left: 2rem;
      padding-right: 2rem;
      .inner-unlock {
        .unlock-image {
          display: none;
        }
      }
    }
  }
  .start {
    width: 100%;
  }
}
