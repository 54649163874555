.syllabus {
  border: 1px solid black;
  background-color: #fff;
  .syllabus-header {
    padding: 2rem;
    .header-flex {
      display: flex;
      justify-content: space-between;
      h2 {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 0.5rem;
      }
      button {
        font-size: 1rem;
        color: blue;
        font-weight: 700;
      }
    }
    ul {
      display: flex;
      align-items: center;
      font-size: 1rem;
      gap: 2rem;
    }
    .flex-side {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .all {
        color: blue;
        font-size: 1rem;
        font-weight: 700;
        cursor: pointer;
      }
    }
  }
  hr {
    background: #000;
    height: 1.5px;
    outline: none;
  }
  .syllabus-accordion {
    padding: 0 2rem;
  }
  .see-more-syllabus {
    padding: 15px;
    display: flex;
    justify-content: center;
    font-size: 1rem;
    color: blue;
    align-items: center;
    font-weight: bold;
    button {
      padding: 8px 15px;
      border-radius: 4px;
      transition: 0.4s ease;
      &:hover {
        background: rgba(#ccc, 0.2);
      }
    }
  }
}

@media (max-width: 768px) {
  .syllabus {
    .syllabus-header {
      ul {
        gap: 0.5rem;
      }
      .flex-side {
        flex-direction: column;
        align-items: flex-start;
        .all {
          margin-top: 5px;
        }
      }
    }
  }
}
