.popular-courses {
  position: relative;
  text-align: center;
  color: white;
  height: auto;
  width: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("/images/courses-bg.jpg");
  padding: 1.25rem 5rem;
  .popular-course {
    font-weight: bold;
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin-top: 0.25rem;
    margin-bottom: 1.25rem;
    div {
      font-size: 20px;
    }
  }
  .mobile-categories {
    display: none;
  }
  .categories {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    height: 50px;
    font-size: 1.1rem;
    line-height: 1.75rem;
    .arrow {
      display: none;
    }
    .selected {
      color: #6b8dff;
      border-bottom: 1px solid #6b8dff;
    }
    .pointer {
      cursor: pointer;
    }
  }
  .course-desc-parent {
    position: relative;
    z-index: 999;
    color: #10162f;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    margin-top: 1rem;
    gap: 1rem;
  }
  .category-accordion {
    display: none;
  }
  .full-catalog {
    position: relative;
    z-index: 999;
    background: #2554f2;
    border-radius: 5px;
    cursor: pointer;
    width: fit-content;
    font-weight: 700;
    padding: 0 1rem;
    margin: 20px auto;
    font-size: 16px;
    color: #10162f;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    a {
      color: white;
    }
    &:hover {
      background: #00247e;
    }
  }
}

@media (max-width: 892px) {
  .popular-courses {
    .course-desc-parent {
      margin-top: 3rem;
    }
  }
}
//Mobile size

@media (max-width: 768px) {
  .popular-courses {
    padding: 1.25rem;
    .mobile {
      display: none;
    }
    .accordion-category {
      display: none;
    }
    .category-accordion {
      display: block;
    }
    .full-catalog {
      margin: 30px auto 10px auto;
    }
  }
}
