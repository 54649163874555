.code-editor {
  position: relative;
  z-index: 99;
  display: flex;
  align-items: center;
  background-color: #10162f;
  gap: 3rem;

  .inner-code-editor {
    padding: 60px 6rem;
    width: 60%;
    display: flex;

    .editor-relative {
      width: 100%;
      .editor-header {
        background-color: black;
        .header {
          width: 100%;
          margin: auto;
          color: #ffffff;
          .logo {
            div {
              width: 95%;
              margin: auto;
              padding: 0.25rem 0;
              a {
                cursor: pointer;
                img {
                  transition: 0.2s all linear;
                  border-radius: 5px;
                  padding: 0.5rem;

                  &:hover {
                    background: rgba(white, 0.1);
                  }
                }
              }
            }
          }
          .code-output {
            .b {
              width: 95%;
              margin: auto;
              display: flex;
              align-items: center;
              justify-content: space-between;
              color: white;
              font-size: 1rem;
              padding: 14px 0;
              div {
                display: flex;
                align-items: center;
                gap: 1rem;
              }
            }
          }
        }
      }
      .code {
        display: flex;
        justify-content: space-between;
        .editor {
          width: 50%;
        }
        .output {
          width: 50%;
          height: 300px;
          color: white;
          background: #0a0d1c;
          padding: 0 1.25rem;
          font-size: 1rem;
          overflow: auto;
        }
      }
    }
    .run {
      background: #000;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        font-size: 0.875rem;
        line-height: 1.25rem;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 65px;
        padding: 0.5rem 1.75rem;
        border-radius: 4px;
        font-weight: 500;
        background-color: #2554f2;
        color: white;
        transition: all 0.3s ease;
        &:hover {
          background: #00247e;
        }
      }
    }
  }

  .continue {
    min-width: 375px;
    max-width: 25%;
    color: white;
    span {
      font-size: 1rem;
      padding-bottom: 1rem;
      font-weight: 500;
    }
    h2 {
      font-size: 36px;
      padding-bottom: 1rem;
      font-weight: 500;
    }
    p {
      font-size: 1rem;
      padding-bottom: 1rem;
    }
    div {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      align-items: center;
      row-gap: 1rem;
      justify-content: space-between;
      a {
        font-weight: 500;
        padding: 0 1rem;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        font-size: 1rem;
        transition: all 0.3s ease;
        border-radius: 4px;
      }
      :first-child {
        border: 2px solid #2554f2;
        background: white;
        color: #2554f2;
        &:hover {
          opacity: 0.8;
        }
      }
      :last-child {
        background: #2554f2;
        color: white;
        &:hover {
          background: #00247e;
        }
      }
    }
  }
}

.box1 {
  box-shadow: 0 0 1px 0.2px white;
  overflow: hidden;
}

@media (max-width: 1200px) {
  .code-editor {
    gap: 0;
    .inner-code-editor {
      padding: 60px 4rem;
    }
  }
}
@media (max-width: 1024px) {
  .code-editor {
    flex-direction: column-reverse;
    row-gap: 3rem;
    align-items: flex-start;
    padding: 4rem;
    .inner-code-editor {
      padding: 0;
      width: 80%;
    }
    .continue {
      min-width: 100%;
      padding: 0 2rem;
    }
  }
}
@media (max-width: 768px) {
  .code-editor {
    padding: 1rem;
    .inner-code-editor {
      width: 100%;
    }
    .continue {
      h2 {
        font-size: 26px;
      }
    }
  }
}

@media (max-width: 480px) {
  .code-editor {
    .continue {
      div {
        justify-content: center;
        row-gap: 0.5rem;
      }
    }
  }
}
