footer {
  background-image: url("/images/footer-threee.jpg");
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 50px;
  .footer-container {
    width: 90%;
    margin: 0 auto;
    padding-bottom: 50px;
    display: grid;
    grid-template-columns: 1.5fr repeat(3, 1fr);
    .footer-elements {
      display: flex;
      flex-direction: column;
      font-size: 1rem;
      gap: 1.5rem;
      .footer-blog {
        a {
          display: flex !important;
          gap: 2rem;
          align-items: center;
          justify-content: center;
          div {
            img {
              height: 50px;
              width: 50px;
              object-fit: contain;
            }
          }
        }
      }
      .footer-detail {
        padding-right: 0;
      }
      a {
        padding-right: 20px;
        img {
          height: auto;
          width: auto;
        }
      }
      &:first-child {
        color: white;
      }
      &:not(:first-child) {
        li {
          &:not(:first-child) {
            color: white;
            a {
              display: block;
              width: fit-content;
              transition: all 0.3s ease;
              &:hover {
                transform: translateX(5px);
                color: #0554f2;
              }
            }
          }
        }
      }

      .footer-element-line {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: 600;
        gap: 0.5rem;
        color: white;
        margin-bottom: 10px;
        p {
          width: 30px;
          height: 3px;
          background: #0554f2;
          line-height: normal;
        }
      }
      .footer-links {
        display: flex;
        align-items: center;
        gap: 1rem;
        a {
          font-size: 1.3rem;
          padding: 10px;
          border-radius: 50%;
          background: #3d65de;
          &:nth-child(3) {
            background: red;
          }
          &:nth-child(4) {
            background: #25d366;
          }
          &:last-child {
            background: #f5f5f5;
          }
        }
      }
    }
    .contact-menu {
      li:not(:first-child) {
        display: flex;
        align-items: center;
        gap: 1rem;
        .contact-text {
          :first-child {
            font-weight: 600;
            font-size: 20px;
          }
          :last-child {
            font-size: 1rem;
            color: #9eb3ef;
          }
        }
        .contact-logo {
          background: #0554f2;
          padding: 7px;

          border-radius: 50%;
          svg {
            height: 22px;
            width: 22px;
          }
        }
      }
    }
  }
  .footer-line {
    border-style: solid;
    border-width: 1px 0px 0px 0px;
    border-color: #4568dc;
    padding: 1.5rem;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 1rem;
    }
  }
}

@media (max-width: 1200px) {
  footer {
    .footer-container {
      width: 90%;
      padding-right: 30px;
      padding-left: 30px;
    }
  }
}

@media (max-width: 992px) {
  footer {
    .footer-container {
      width: 80%;
      grid-template-columns: 3fr 1fr;
      row-gap: 2rem;
      .footer-elements {
        a {
          padding-right: 0;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  footer {
    .footer-container {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .footer-elements {
        align-items: flex-start;
        width: 100%;
        margin: 0 auto;
        img {
          width: 200px;
          object-fit: contain;
        }
      }
    }
  }
}
