.auth-content {
  width: 70%;
  padding: 2rem;
  .auth-content-container {
    .auth-title {
      color: #0c5adb;
      letter-spacing: 5px;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 15px;
    }
    .auth-header{
      font-size: 20px;
      color: #232323;
      font-weight: 700;
    }
    .auth-header-blue{
      color: #0c5adb;
    }
    ul {
      margin-top: 30px;
      li {
        margin-bottom: 30px;
        a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background-color: #0c5adb;
          color: white;
          font-size: 16px;
          font-family: "Open Sans", Sans-serif;
          border-radius: 5px;
          font-weight: 600;
          height: 40px;
          padding: 0 1rem;
          transition: all 0.4s ease;
          &:hover {
            background: #00247e;
          }
        }

        .list-item {
          display: flex;
          align-items: center;
          // justify-content: space-between;
          gap: 2rem;
          .list-icon {
            color: #0c5adb;
            font-size: 20px;
            height: 40px;
            width: 40px;
            img {
              max-width: none;
              // width: auto !important;
              // height: 50px !important;
            }
          }
          h4 {
            font-weight: 700;
            font-size: 16px;
          }
          p {
            font-size: 16px;
            font-family: "Muli sans serif";
            color: #616161;
            letter-spacing: 1px;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .auth-content {
    width: 100%;
    .auth-content-container {
      .auth-header,
      .auth-title {
        text-align: center;
      }
    }
  }
}

@media (max-width: 450px) {
  .auth-content {
    padding: 0;
  }
}
