.our-team {
  width: 100%;
  background-image: url("/images/team.jpg");
  padding: 2rem 0;
  .inner-team {
    padding: 0 20px;
    margin: 0 auto;
    .team-header {
      margin-bottom: 50px;
      max-width: 1140px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      button {
        background: #0c5adb;
        padding: 0 1rem;
        height: 40px;
        border-radius: 5px;
        font-size: 16px;
        color: white;
        font-weight: 600;
        transition: 0.3s ease;
        &:hover {
          background: #00247e;
        }
      }
      .header-container {
        font-family: sans-serif;
        .team-title {
          display: flex;
          align-self: center;
          font-weight: 600;
          font-size: 20px;
          color: #0554f2;
          gap: 2rem;
          div {
            display: flex;
            align-items: center;
            justify-content: center;
            p {
              width: 50px;
              height: 2px;
              background: #0554f2;
              line-height: normal;
            }
          }
        }
        h2 {
          margin-top: 5px;
          font-size: 30px;
          font-weight: 700;
          span {
            margin-top: 0;
            display: block;
            font-size: 18px;
          }
        }
      }
    }
    .flex {
      gap: 1.5rem;
      display: grid;
      max-width: 1140px;
      width: 100%;
      margin: 0 auto;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: max-content;
      .flex-img-item {
        // max-width: 200px;
        cursor: pointer;
        position: relative;
        transition: 0.4s ease;
        overflow: hidden;
        .linkedinLink {
          position: absolute;
          left: 50%;
          top: 50%;
          z-index: 10;
          padding: 10px;
          transform: translate(100%, -160%);
          color: #0554f2;
          background-color: white;
          font-size: 1rem;
          opacity: 0;
          transition: all 0.4s ease;
          a {
            width: 15px;
            height: 15px;
          }
        }
        &:hover {
          transform: translateY(-20px);
          .linkedinLink {
            opacity: 1;
            transform: translate(-50%, -160%);
          }
          .member-info {
            height: 100%;
            .member-image {
              .member-image-shadow {
                opacity: 1;
              }
            }
          }
        }
        & > a {
          .member-info {
            height: 100%;
            display: flex;
            justify-content: center;
            font-size: 15px;
            border-radius: 5px;
            border-bottom: 3px solid #0c5adb;
            background: white;
            .member-image {
              width: 100%;
              height: 180px;
              position: relative;
              img {
                margin-top: 25px;
                border-radius: 50%;
                width: 200px;
                height: 200px;
                object-fit: contain;
              }
              .member-image-shadow {
                position: absolute;
                width: 100%;
                height: 200px;
                background-color: rgba($color: #0c5adb, $alpha: 0.7);
                top: 0;
                border-radius: 50%;
                opacity: 0;
                left: 0;
                transition: 0.3s ease;
              }
            }
            .group {
              margin-top: 30px;
              padding: 20px 10px 40px 10px;
              text-align: center;
              .bold {
                font-weight: 600;
                font-size: 18px;
                margin: 0;
              }
              p {
                font-size: 15px;
                color: #0554f2;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
    .see-more-members {
      margin-top: 40px;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        font-weight: 600;
        color: white;
        button {
          display: flex;
          border-radius: 5px;
          justify-content: center;
          align-items: center;
          background-color: #2554f2;
          height: 40px;
          padding: 0 1rem;
          transition: all 0.3s ease;
          &:hover {
            background: #00247e;
          }
        }
      }
    }
  }
}

.flex-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  .flex-img-item {
    .member-info {
      font-size: 15px;
      padding: 1rem 0;
      p {
        font-weight: bold;
        font-size: 19px;
        margin: 0;
      }
    }
  }
}

.flex-box-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  .flex-img-item {
    .member-info {
      font-size: 15px;
      padding: 1rem 0;
      font-weight: normal;
      .ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      p {
        font-size: 19px;
        margin: 0;
      }
    }
  }
}

@media (max-width: 1200px) {
  .flex-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1024px) {
  .our-team {
    .inner-team {
      width: 90%;
      .flex {
        max-width: 600px;
        width: 100%;
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
@media (max-width: 968px) {
  .team-header {
    text-align: center;
  }

  .flex-box,
  .flex-box-3 {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    .flex-box,
    .flex-box-3 {
      justify-content: center;
    }
  }
}
@media (max-width: 768px) {
  .our-team {
    .inner-team {
      .team-header {
        button {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .our-team {
    .inner-team {
      .flex {
        max-width: 300px;
        width: 100%;
        grid-template-columns: 1fr;
      }
    }
  }
}
