.course-containers {
  position: relative;
  margin: 2rem 0;
  height: 300px;
  width: 240px;
  border-radius: 5px;
  box-shadow: 0 0 0 black;
  transition: 0.5s ease;
  font-weight: 400;
  &:hover {
    // box-shadow: -8px 8px 0 white;
    translate: 4px -4px;
  }
  a {
    width: 100%;
    height: 100%;
  }
  .course-desc {
    border-radius: 5px;
    width: 100%;
    color: #10162f;
    height: 100%;
    background: #fff;
    cursor: pointer;
    transition: transform 0.5s;
    .hr {
      border-bottom: 1px dashed black;
      margin-bottom: 5px;
      width: 95%;
      margin: 0 auto;
    }
    .article {
      background: white;
      border-bottom: 1px solid black;
    }
    .skill-path {
      background: #0c5adb;
      // background: #d3f2ff;
    }
    .career-path {
      background: #0c5adb;
      // background: #10162f;
      // color: white;
    }
    .other {
      background: #0c5adb;
      // background: #eafdc6;
    }
    .course-free {
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      font-family: Suisse, Apercu, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
      font-size: 16px;
      text-align: left;
      color: white;
      padding: 0.25rem 0 0.2rem 0.75rem;
    }
    .course-title {
      font-weight: 700;
      line-height: 1.3;
      font-size: 1.25rem;
      padding: 1rem 1rem 0 1rem;
      margin-bottom: 0.75rem;
    }
    .course-sub-desc {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 3;
      font-size: 14px;
      padding: 0 1rem;
    }
    .level {
      position: absolute;
      bottom: 10px;
      width: 100%;
      text-align: left;
      font-size: 13px;
      .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 0 0 5px;
        gap: 0.25rem;
        .text {
          font-weight: 600;
          font-size: 0.8rem;
        }
        .duration {
          padding: 5px 10px 0 0;
          span {
            font-weight: 600;
          }
        }
      }
    }
  }
}

.career-path-parent,
.skill-path-parent {
  box-shadow: -6px 6px 0 white;
  border: 1px solid black;
}

.detail {
  width: 330px;
  margin: 0;
}

@media (max-width: 1440px) {
  .detail {
    width: 31% !important;
  }
}
@media (max-width: 1024px) {
  .detail {
    width: 100% !important;
  }
}
//Mobile size

@media (max-width: 768px) {
  .course-containers {
    margin: 0 0 2rem 0;
    .course-desc {
      .shadow {
        z-index: -1;
        width: 90%;
      }
      .course-sub-desc {
        height: fit-content;
      }
    }
  }
}
