.about {
  background-color: #f5fcff;
  .inner-about {
    width: 90%;
    margin: 0 auto;
    padding: 64px 48px;
    .about-header {
      display: flex;
      justify-content: space-between;
      .about-left-side {
        width: 100%;
        color: #10162f;
        .about-academy {
          font-size: 3.5rem;
          font-weight: 700;
          margin-bottom: 15px;
        }
        p {
          font-size: 14px;
          margin-bottom: 20px;
        }
        button {
          width: 200px;
          background: #3a10e5;
          color: #fff;
          height: 45px;
          font-weight: 700;
          font-size: 15px;
          box-shadow: rgb(0, 0, 0) -5px 5px 0px;
          transition: 0.3s ease;
          &:hover {
            box-shadow: rgb(0, 0, 0) -8px 8px 0px;
          }
        }
      }
      .iframe {
        width: 80%;
        margin: 0 auto;
        object-fit: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        iframe {
          width: 80%;
        }
      }
    }
    .our {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 100px;
      .mission {
        margin-left: 100px;
      }
      p {
        color: #10162f;
        font-size: 1.625rem;
        font-weight: 700;
        margin-bottom: 20px;
      }
      .content {
        font-size: 16px;
        background: inherit;
        line-height: 1.6;
      }
    }
    .our-team-header {
      margin: 100px 0 50px 0;
      font-size: 30px;
      color: #10162f;
      text-align: center;
      font-weight: 700;
    }
    .our-team-about {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: flex-start;
      // width: 90%;
      margin: 100px auto 0 auto;
      width: 100%;
      column-gap: 6rem;
      cursor: pointer;
      .our-team-item {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        flex-direction: column;
        gap: 0.5rem;
        width: 15%;
        &:hover {
          .member-info {
            color: blue;
          }
        }
        .image-profile {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          img {
            height: 120px;
            width: 120px;
            object-fit: fill;
            border-radius: 50%;
          }
        }
        .member-info {
          margin-top: 10px;
          color: #10162f;
          text-align: center;
          transition: all 0.2s linear;
          h3 {
            font-weight: 700;
            font-size: 1.25rem;
          }
          p {
            font-size: 14px;
          }
        }
      }
    }
  }
  .our-office {
    background-color: #10162f;
    color: #fff;
    padding: 5rem;
    .office-header {
      font-size: 2.125rem;
      font-weight: 700;
      text-align: center;
      margin-bottom: 40px;
    }
    .map-iframe {
      width: 100%;
    }
    .address {
      text-align: center;
      font-size: 1rem;
      margin-top: 40px;
    }
  }
}
@media (max-width: 1200px) {
  .about {
    .inner-about {
      .about-header {
        .about-left-side {
          width: 50%;
          .about-academy {
            font-size: 35px;
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .about {
    .inner-about {
      .our {
        flex-direction: column;
        gap: 2rem;
        .mission {
          margin: 0;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .about {
    .inner-about {
      width: 100%;
      padding: 40px 20px;
      .about-header {
        flex-direction: column;
        justify-content: center;
        .about-left-side {
          width: 100%;
          .about-academy {
            font-size: 27px;
          }
          p {
            font-size: 15px;
          }
          button {
            width: 100%;
            &:after {
              width: 100%;
            }
            &::before {
              width: 100%;
            }
          }
        }
        .iframe {
          width: 100%;
          margin-top: 60px;
          iframe {
            width: 100%;
          }
        }
      }
    }
    .our-office {
      padding: 1rem;
      .map-iframe {
        width: 100%;
        iframe {
          height: 350px;
        }
      }
    }
  }
}