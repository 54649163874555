.enroll {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    gap: 20px;
    width: max-content;
    background-color: #fff;
    padding: 20px 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: "Arial", sans-serif;
    font-size: 18px;
    text-align: center;
    color: #333;

    a {
      color: #0070f3;
      text-decoration: none;
      font-weight: bold;
      &:hover {
        color: #005bb5;
      }
    }
  }
}
